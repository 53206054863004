.TCGL_V_A1_Lenders {
  .btn-banner {
    max-width: unset !important;
    width: 100%;
  }
.lender-div{
    padding: 0 10px;
    border: 2px solid blue;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: -15px;
}
.top-div{
  top: 25%
}
.top-sub{
  top: 6.5%;
}
  .lenders {
    // selectoptions
    .selectoptions {
      input[type="checkbox"] + label {
        background: url(../../img/TCGL_V_A1/uncheck.png) no-repeat left center;
        background-position: 15px;
        background-size: 20px;
        border-radius: 3px;
        background-color: #fff;
        color: #090909;
        cursor: pointer;
        border: 2px #b8b8b8 solid;
        cursor: pointer;
        display: inline-block;
        // height: 40px;
        height: auto;
        padding: 15px 0;
        width: 100%;
        border-radius: 10px;
        text-align: center;
        margin-bottom: 15px;
        box-shadow: 0 12px 10px -10px rgba(0, 0, 0, 0.2);
        transition: 0.4s ease-in-out 0s;
        @media screen and (max-width: 768px) {
          margin-bottom: 5px;
          border-radius: 7px;
        }
      }
      input[type="checkbox"]:checked + label {
        background: url(../../img/TCGL_V_A1/check.png) no-repeat left center;
        background-position: 15px;
        background-size: 20px;
        border: 2px #2145dd solid;
        cursor: pointer;
        display: inline-block;
        background-color: #fff;
        color: #2145dd;
      }
      label {
        // float: left;
      }
      input[type="checkbox"] {
        display: none;
      }
    }

    .option {
      float: left;
      height: auto;
      margin: 0 0 0px 0;
      width: 100%;
      padding: 0 0px;
    }
    .option_title {
      float: left;
      font-size: 16px;
      font-weight: 500;
      height: auto;
      // line-height: 18px;
      margin-left: 45px;
      margin-top: 0;
      position: relative;
      text-align: left;
      text-decoration: none;
      top: 0;
      z-index: 120;

      @media (max-width: 768px) {
        font-size: 14px;
        width: 70%;
      }
      img {
        max-height: 25px;
        margin-right: 7px;
      }
    }
    // selectoptions

    fieldset {
			border: 1px solid #6e90b9 !important;
			padding: 10px;
			border-radius: 10px;
		}
		fieldset.fieldset-v1c {
			border: none !important;
		}
		legend {
			float: inherit;
			width: 90%;
			font-size: 18px;
			color: #424242;
			font-weight: 600;
			margin-bottom: 20px;
		}
  }
  
  .morelenderbut {
    cursor: pointer;
  }

  .accordion-header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #0031ff52;
    border-radius: 5px;
    border: 2px #2145dd69 solid;
    cursor: pointer;
    h3 {
      margin-top: 0px !important;
      button {
        text-align: left;
        text-decoration: none;
        box-shadow: none !important;
        .btn {
          &.active:focus {
            box-shadow: none !important;
          }
        }
      }
    }
  }
  .btn-link {
    text-decoration: none;
    font-weight: bold;
    color: black;
    flex-grow: 1;
    text-align: left;
  }
  .selected-symbol {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: darkgreen;
    color: white;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    margin-right: 10px;
  }
  .arrow-icon {
    font-size: 13px;
    color: #2145dd;
  }
  .accordion-body {
    padding: 10px;
    background-color: #c0d2ff;
    text-align: left;
    font-size: 13px;
    border: 2px solid #2145dd69;
    border-radius: 5px;
  }
  .lender-list {
    list-style: none;
    padding-left: 0;
  }
  .lender-list li {
    padding: 5px 0;
  }
  .lender-list hr {
    border: none;
    border-top: 1px solid #2145dd;
    margin: 1px 0 !important;
  }
  .vbtn {
    padding: 12px !important;
    border-radius: 26px !important;
  }

  .selectoptions {
    &.lenders-block {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      .lend-items {
        position: relative;
        width: 47%;
        .label {
          background: #d32e2e;
          position: absolute;
          right: 10px;
          left: 10px;
          margin: 0 auto;
          top: 7px;
          font-size: 11px;
          right: 12px;
          padding: 7px 13px 7px 5px;
          line-height: 13px;
          border-radius: 20px;
          color: #fff;
          img {
            height: 20px;
          }
        }

        input[type="checkbox"] + label {
          background: none;
          padding: 30px 10px;
          min-height: 180px;
          text-align: center;
          word-break: break-all;
          padding-top: 50px;
          color: black;
          box-shadow: initial;
          font-size: 12px;
          border: 2px solid #d7d7d7;
          font-weight: bold;
          i {
            font-size: 35px;
          }
          .option_title {
            margin-left: 0;
            font-size: 12px;
            float: initial;
            word-break: auto-phrase;
          }
        }

        input[type="checkbox"]:checked + label {
          border: none;
          padding: 30px 10px;
          padding-top: 50px;
          min-height: 180px;
          font-size: 12px;
          border: 2px solid #3e9bc4;
          color: #376377;
          background: #c1ecff;
          box-shadow: 3px 4px 3px 1px lightblue;
          .option_title {
            font-weight: bold;
          }
        }
      }
      &.single-lender {
        display: initial;
        .lend-items {
          width: 70%;
          margin-left: auto;
          margin-right: auto;
          .label {
            font-size: 16px;
            top: 10px;
          }
          .option_title {
            font-size: 15px !important;
            word-break: auto-phrase;
          }
          input[type="checkbox"] + label {
            padding: 30px 23px;
            padding-top: 70px;
          }
          input[type="checkbox"]:checked + label {
            font-size: 18px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .mob_sticky {
      position: fixed;
      bottom: 0;
      left: 12px;
      width: 100%;
      background-color: #ffffff; /* Adjust as needed */
      z-index: 1000; /* High z-index to stay on top */
      // padding: 10px 0;
      box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for visibility */
      transition: none !important;
      .btn-banner {
        font-size: 17px !important;
      }
      .btn-back {
        font-size: 17px !important;
      }
    }
  }

  .custom-accordion-wrapper {
    width: 97% !important;
    .accordion {
      border: 2px solid #3e9bc4;
      margin-bottom: 5px;
      background-color: #a3ddf7;
      border-radius: 5px;
      padding: 5px;
      &:not(:first-of-type) {
        border-top: 1 !important;
      }

      .accordion-header {
        display: flex;
        align-items: center;
        padding: 2px 10px;
        background-color: #c1ecff;
        border-radius: 5px;
        border: 2px solid #52bfef;

        .btn-link {
          text-decoration: none;
          font-weight: bold;
          color: #000;
          flex-grow: 1;
          text-align: left;
        }

        .selected-symbol {
          display: inline-block;
          width: 29px;
          height: 20px;
          background-color: darkgreen;
          color: white;
          border-radius: 50%;
          text-align: center;
          line-height: 20px;
          font-size: 14px;
          margin-right: 10px;
        }

        .arrow-icon {
          font-size: 13px;
          color: #2145dd;
        }
      }

      .accordion-body {
        padding: 10px;
        background-color: #c1ecff;
        text-align: left;
        font-size: 13px;
        border: 2px solid #b6e2f5;
        border-radius: 5px;
        .lender-list {
          list-style: none;
          padding-left: 0;

          li {
            padding: 5px 0;

            hr {
              border: none;
              border-top: 1px solid #2145dd;
              margin: 1px 0 !important;
            }
          }
        }
      }
    }
  }

  .accordion {
    border: 2px solid #2145dd;
    margin-bottom: 5px !important;
    background-color: #0031ff29;
    border-radius: 5px !important;
    padding: 5px !important;

    .accordion-item{
      border: none !important;
    }

    &.creditcheck-accordion {
      background-color: #a3ddf7;
      border: 2px solid #3e9bc4;

      .accordion-header {
        background-color: #c1ecff !important;
        border: 2px solid #52bfef !important;
      }
      .accordion-body{
        background-color: #c1ecff !important;
        border: 2px solid #b6e2f5 !important;
      }
    }
  }

  .accordion-header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #0031ff52;
    border-radius: 5px;
    border: 2px solid #2145dd69;

    .btn-link {
      text-decoration: none;
      font-weight: bold;
      color: black;
      flex-grow: 1;
      text-align: left;
    }

    .selected-symbol {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: darkgreen;
      color: white;
      border-radius: 50%;
      text-align: center;
      line-height: 20px;
      font-size: 14px;
      margin-right: 10px;
    }

    .arrow-icon {
      font-size: 13px;
      color: #2145dd;
    }
  }

  .accordion-body {
    padding: 10px !important;
    background-color: #c0d2ff;
    text-align: left;
    font-size: 13px;
    border: 2px solid #2145dd69;
    border-radius: 5px;
  }

  .lender-list {
    list-style: none;
    padding-left: 0;

    li {
      padding: 5px 0;
    }

    hr {
      border: none;
      border-top: 1px solid #2145dd;
      margin: 1px 0 !important;
    }
  }

  .next-css{
    max-width: unset !important;
    min-width: unset !important;
  }
  .new-color {
		background: grey;
		&.grey-btn:hover{
			background-color: grey !important;
		}
	}
	.success-color-v1c {
		background: #0c6c0a !important;
	}
	.see-more-btn{
		background-color: #2145dd;
	}
	.grey-btn{
		background-color: grey;
	}
  .pulse-anime {
    animation: btn_anime 1.5s cubic-bezier(.57,.31,.36,1) infinite;
    border: 2px solid grey;
  }

    .lenderslide-button {
      cursor: pointer;
      display: inline-block;
      border: none;
      padding-top: 15px !important;
      padding-bottom: 15px !important;

      &.max {
        background-color: #0c6c0a;
        color: #ffffff;
      }
      &.manual {
        background-color: #727272;
        color: #ffffff;
      }
    }
    .recommended{
      font-size: 12px;
    }

  @keyframes btn_anime {
      0% {
          box-shadow: 0 0 0 0 rgba(12, 108, 10, .6980392156862745);
      }
      100% {
          box-shadow: 0 0 0 12px rgba(255, 105, 180, 0);
      }
  }
}
.zoom-in-zoom-out {
animation: zoom-in-zoom-out 2s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
0% {
  transform: scale(1, 1);
}
50% {
  transform: scale(1.03, 1.03);
}
100% {
  transform: scale(1, 1);
}
}