.SignatureV10 {
    @import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

    $color1: #2145dd;
    $color3: #111111;
    $color_1: #fff;

    @mixin btn-style1 {
        background: #0c6c0a;
        border-radius: 30px;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        padding: 15px;
        max-width: 275px;
        display: block;
        text-align: center;
        -webkit-box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
        margin: 10px auto 20px;
      }
    canvas#canvas_signature {
        width: 100%;
        height: 300px;
    }
    .signpage{
    padding:40px 0;
}

.viewterms{
    color: #3679EF;
    background: #DBE8FF;
    border: solid 1px #9DC1FF;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    display: block;
    text-align: center;
    font-size: 14px;
    margin-bottom: 15px;
}
.signaturetext{
    .form-check-box {
        /* border: 1px solid #0c6c0a; */
        padding: 1px 15px 16px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
        background-color: #0c6c0a1f;
        &.check-box-unchecked{
            background-color: #ff030325;
            color: red;
        }
    }
    p{
        font-size: 13px;



    }
    .form-check-input{
        appearance: auto !important;


                scale: 1.3;
                margin-top: 6px;


    }
}

.signaturepad{
    width: 100%;
    background: #ffff url("../../img/TCGL_V_A1/signhere.png") no-repeat center center ;
    max-height: 300px;
    border-radius: 5px;
    margin: 0px 0 10px;
    border: solid 3px #B9B9B9;
    margin-bottom: 15px;
    background-size:130px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    @media (min-width: 992px) {
        border-radius: 9px;
    }
}
header {
    background: #ffffff;
    -webkit-box-shadow: 1px 2px 12px -1px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 1px 2px 12px -1px rgba(0, 0, 0, 0.23);
    box-shadow: 1px 2px 12px -1px rgba(0, 0, 0, 0.23);
    position: relative;
    z-index: 999;
    padding: 10px 0;
  }
  footer {
    background: $color3;
    color: $color_1;
    font-size: 0.8rem;

    .link-list {
      color: #fff;
      margin-bottom: 10px !important;

      li a {
        color: #fff;
      }
    }

    ul {
      li {
        display: inline-block;

        a {
          padding: 0 15px 0 0;
        }
      }
    }

    p {
      a {
        color: #1c33ff;
        text-decoration: underline;
      }
    }
  }

.form-header {
    padding: 15px 0;
    border-bottom: solid 1px #BABABA;
    margin-bottom: 35px;

    h4 {
        color: #A0A0A0;

        span {
            color: #000;
        }
    }

    h5 {
        color: #000;
        font-size: 18px;
        font-weight: 600;
    }

    p {
        font-size: 14px;
        color: #000;
        margin: 0px;
    }

    .bor-l {
        border-left: solid 1px #BABABA;
        padding: 10px 15px;
    }

    img {
        max-height: 60px;
    }
}

.a-d {
    z-index: 99;
}

.signature-wraper {
    position: relative;
    .clear-button {
        background: #2353ff;
        position: absolute;
        bottom: 26px;
        right: 25px;
        border: 0;
        padding: 6px 21px;
        color: #fff;
        border-radius: 15px;
        font-size: 15px;
    }
}
.error_msg{
    background-color: #df0707d7;
    color: white !important;
    display: block;
    padding: 0px 10px;
    border-radius: 5px;
    margin: 5px 0px;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
}


.pulse-anime {
    animation: btn_anime 1.5s cubic-bezier(.57,.31,.36,1) infinite;
    border: 2px solid grey;
}

@keyframes btn_anime {
    0% {
        box-shadow: 0 0 0 0 rgba(12, 108, 10, .6980392156862745);
    }
    100% {
        box-shadow: 0 0 0 12px rgba(255, 105, 180, 0);
    }
}

.pulse-anime2 {
    // -webkit-animation: btn_anime2 1.5s cubic-bezier(.57,.31,.36,1) infinite;
    // animation: btn_anime2 1.5s cubic-bezier(.57,.31,.36,1) infinite;
    animation: btn_anime2 1.5s cubic-bezier(.57,.31,.36,1) infinite;
    border: 2px solid grey;
    transform: translateZ(0); /* For hardware acceleration */
    transition: box-shadow 1.5s cubic-bezier(.57,.31,.36,1) infinite
}

@-webkit-keyframes btn_anime2 {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(3, 18, 230, 0.698);
        -moz-box-shadow: 0px 0px 0px 0px rgba(3,18,230,0.698);
        box-shadow: 0 0 0 0 rgba(3, 18, 230, 0.698);
    }
    100% {
        -webkit-box-shadow: 0 0 0 12px rgba(255, 105, 180, 0.0);
        -moz-box-shadow: 0px 0px 0px 12px rgba(255,105,180,0.0);
        box-shadow: 0 0 0 12px rgba(255, 105, 180, 0.0);
    }
}

@keyframes btn_anime2 {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(3, 18, 230, 0.698);
        box-shadow: 0 0 0 0 rgba(3, 18, 230, 0.698);
    }
    100% {
        -webkit-box-shadow: 0 0 0 12px rgba(255, 105, 180, 0.0);
        box-shadow: 0 0 0 12px rgba(255, 105, 180, 0.0);
    }
}


.arrow-down{
    position: absolute;
    right: 20%;
}
.a-d{
    animation: MoveUpDown 1s linear infinite;
    left: 62px;
    bottom: 0;
    position: relative;
    margin-top: 30px;
}

@keyframes MoveUpDown {
    0%,100% {
        bottom: -10px
    }

    50% {
        bottom: 5px
    }
}

.signature-wraper  {
    p {
        font-size: 14px;
        font-weight: bold;
        color: #2353ff;

    }
    .a-d {
        height: 55px;
        z-index: 999;
    }
    .arrow-down {
        right:15%;
        bottom: -50px;
    }
}


@media only screen and (min-width: 992px) {

    .signature-wraper   {
        border:2px solid  #2353ff;

        padding: 20px  ;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        margin-bottom: 2rem;
        border-radius: 5px;
        background-image: linear-gradient(to right bottom, #dbeaff, #dfedff, #e4f0ff, #e8f2ff, #edf5ff);

        canvas {
            background-size: 170px;
        }
    }



}


@media only screen and (max-width: 992px) {

    .signature-wraper  {

        .clear-button {
            font-size: 12px;
            padding: 4px 15px;
            bottom: 10px;
        }

    }


        .mob_sticky1 {
          position: fixed;
          bottom: 0;
          left: 0px ;
          width: 100%;
          background-color: #ffffff; /* Adjust as needed */
          z-index: 1000; /* High z-index to stay on top */
          padding: 10px 0;
          box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for visibility */
          transition: none !important;
        .btn-banner {
            @include btn-style1;
            margin: 10px 0 20px;
            border: 0px;
            min-width: 350px;
            font-size: 17px !important;
            display: inline-block;

            &:hover {
              background: #35c730 !important;
            }

            span {
              margin-left: 20px;
            }
          }
        .btn-back {

            font-size: 17px !important;

        }

      }


}


.sigp{
    font-size: 13px !important;
    margin-top: -20px !important;
    width: 80%;
  }

  .ulclass{
    padding-left: 0;
    margin-bottom: 20;
    list-style: none;
    border-radius: 14px;
   .liclass {
        margin-top: 15px;
        position: relative;
        padding-left: 25px;
        font-size: 13px;
        &::after  {
            position: absolute;
            left: 0;
            top: 4px;
            content: "";
            height: 10px;
            width: 10px;
            background: #02a1e5;
            border-radius: 3px;
            opacity: .6;
        }
        .sub_ul {
            .sub_li{

                    list-style-type: circle !important;


            }

        }
    }

  }
  .cur-pointer{
    cursor: pointer;
  }
  .sign-aftr-div{
    background: #246d2b !important;
    color: #fff !important;
    padding: 15px 15px !important;
    border: 20px !important;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75) !important;
}


.pacifico-font {
    font-family: 'Pacifico', cursive;
  }  

  .sign-accordion{
    .accordion-header .accordion-button {
        background-color: rgb(70, 190, 225);
        color: white;
    }
    .accordion-button:after {
        --bs-accordion-btn-icon: url(../../img/TCGL_V_A1/chevron-down.svg);
    }
    .accordion-button:not(.collapsed):after {
        --bs-accordion-btn-active-icon: url(../../img/TCGL_V_A1/chevron-down.svg);
    }
  }
  .large-check-box {
    width: 16px;
    height: 16px;
  }
}

.next-css{
    max-width: none !important;
    // min-width: none !important;
    width: 100% !important;
  }


  .scrollbar
  {
    //   margin-left: 30px;
    float: left;
    float: left;
    height: 158px;
    width: 100%;
    background: #ffffff;
    overflow-y: scroll;
    margin-bottom: 25px;
    padding: 10px 13px;
    border-radius: 5px;
    border-width: 2px;
    border: 2px solid #d5d5d5;

  }
  
  .force-overflow
  {
      min-height: 450px;
  }

  #style-1::-webkit-scrollbar-track
  {
      -webkit-box-shadow: inset 0 0 6px rgba(172, 172, 172, 0.432);
      border-radius: 10px;
    //   background-color: #F5F5F5;
  }
  
  #style-1::-webkit-scrollbar
  {
      width: 8px;
    //   background-color: #F5F5F5;
  }
  
  #style-1::-webkit-scrollbar-thumb
  {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(161, 159, 159, 0.3);
      background-color: #979797;
  }

  .pdf-modal-box{
        border: 1.3px solid #d5d5d5;
        padding: 16px;
        // max-width: 80%;
        margin: 20px auto;
        background-color: #fff;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        position: relative;
  }

  #pdf-close-button{
    
    padding: 8px 16px;
    color: rgb(37, 36, 36);
    border: none;
    position:absolute;
    top: 3px;
    right: 3px;
    background-color: white;
     
  }